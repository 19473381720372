@font-face {
  font-family: 'Poppins';
  src: local('Poppins Regular'), local('Poppins-Regular'),
       url('/fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins Medium'), local('Poppins-Medium'),
       url('/fonts/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

/* Repeat for other weights */


html {
  scroll-behavior: smooth;
}

.image-container img{
  background:white;
  width:150px;
  height:150px;
  border-radius: 50%;
  padding: 3px;
  transform: translate(2px,35px);
}
.image-container-re img{
  background:white;
  width:180px;
  height:150px;
  border-radius: 20%;
  padding: 3px;
  /* border-color:#4c004c ; */
  /* background-color:#4c004c; */
  transform: translate(5px,-65px);
}
img{
border:2px solid #4c004c;
} 
.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.content-wrap {
  flex: 1;
   
}

.anchorTag{
  text-decoration:none;
  color: black;
}

.anchorTag:hover{
  text-decoration:underline;
  color: #003366;
}

.main-footer {
  color: white;
  background-color: #4c004c;
  padding: 1em;
  position: relative;
  bottom: 0;
  width: 100%;
}

/* body{
  background-color: #fff;
  font-family: Poppins !important;
} */

.linkstyle:hover {
  color: white;
  text-decoration: none;
}

.linkstyle {
  color: white;
  text-decoration: none;
}


.article_card:hover {
  /* color: white; */
  color: black;
  text-decoration: none;
}

.article_card {
  /* color: white; */
  text-decoration: none;
}

.MuiButton-root{
  text-transform: capitalize !important;
}

.MuiListItem-button:hover{
  color: black;
}

#carousel_image_id_active {
  filter: blur(0px) !important;
  
}


.image-box {
  background-color: #f3eaff; /* light purple background */
  border: 2px solid #000000; /* border around the box */
  border-radius: 10px; /* light curvy corners */
  width: 100%; /* width of the box, adjust as needed */
  height: 200px; /* fixed height of the box */
  display: flex;
  align-items: center; /* vertical center alignment */
  justify-content: center; /* horizontal center alignment */
  overflow: hidden; /* in case the image is too big */
  margin: 0 auto; /* if you want to center the box on the page */
}

.image-box img {
  height: 140px; /* fixed image height, adjust as needed */
  width: 140px; /* fixed image width, adjust as needed */
  object-fit: contain; /* cover the container without stretching */
  border-radius: 8px; /* if you want the image to have curvy corners as well */
}


body {
  font-family: 'Poppins', sans-serif;
}
 

/* === BASE HEADING === */ 

h1 {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  font-size: 40px;
  color: #080808;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

h1 span {
  display: block;
  font-size: 0.5em;
  line-height: 1.3;
}
h1 em {
  font-style: normal;
  font-weight: 600;
}


/* === HEADING STYLE #2 === */
.two h1 {
  text-transform: capitalize;
}
.two h1:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 5px;
  content: "";
  background-color: #4c004c;
}

.two h1 span {
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 4px;
  line-height: 3em;
  padding-left: 0.25em;
  color: rgba(0, 0, 0, 0.6);
  padding-bottom: 10px;
}

/* Block Quote CSS */

blockquote{
  /* font-size: 1.4em; */
  width:100%;
  margin:50px auto;
  /* font-family:Open Sans; */
  font-style:italic;
  color:  rgba(0, 0, 0, 0.6);
  padding:1.2em 30px 1.2em 75px;
  border-left:8px solid #4c004c ;
  border-right:8px solid #4c004c ;
  line-height:1.6;
  position: relative;
  /* padding: 20px; */
   
}

blockquote::before{
  font-family:Arial;
  content: "\201C";
  color:#4c004c;
  font-size:4em;
  position: absolute;
  left: 10px;
  top:-10px;
}

blockquote::after{
  content: '';
}

blockquote span{
  display:block;
  color:#4c004c;
  font-style: normal;
  font-weight: bold;
  margin-top:1em;
}

 
#blockquote_test::before{
  position: absolute;
  left: 30%;
  top: 0%;
  width: 40%;
  height: 4px;
  content: "";
  
  background-color: #d7cadb;  
}

#blockquote_test::after{
  position: absolute;
  right: 30%;
  bottom: 0%;
  width: 40%;
  height: 4px;
  content: "";
  background-color: #d7cadb;  
}

/* Carousel Indicators Colors */
.dot{
  background-color: rgba(76, 0, 76, 0.4) !important;
}
.control-dots .dot.selected, .control-dots .dot:hover {
  background: rgba(76, 0, 76, 0.8) !important; /* Replace #yourActiveColor with the color for active/hover state */
}



.twelve:before {
  background-color: #4c004c;
  content: '';
  display: block;
  height: 3px;
  width: 75px;
  margin-bottom: 5px;
}
 

/* === SERVICE DETAILS TITLE STYLE #3 === */
.service_details_title h1 {
  /* font-size: 28px; */
  font-weight: 500;
  letter-spacing: 4px !important;
  color: #4c004c;
   
  line-height: 1.5em;
  padding-bottom: 15px;
  position: relative;
}
.service_details_title h1:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 10px;
  width: 70px;
  background-color: #4c004c;
}
.service_details_title h1:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 3px;
  height: 1px;
  width: 100%;
  max-width: 255px;
  height: 3px;
  background-color: #4c004c;
}


/* === HEADING STYLE #2 === */
.article_title h1 {
  text-transform: capitalize;
}
.article_title h1:before {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 80px;  
  height: 5px;
  content: "";
  background-color: #4c004c;
}

.article_title h1 span {
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 4px;
  line-height: 3em;
  padding-left: 0.25em;
  text-align: right;
  color: rgba(0, 0, 0, 0.6);
  padding-bottom: 10px;
}


.article_desc{
  letter-spacing: 1px;
}

.teamcard{
  position: relative;
  padding: 30px;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
}


.teamcard:hover {
  box-shadow: 0px 4px 2px -2px rgba(0,0,0,0.3), 
              0px 2px 2px 0px rgba(0,0,0,0.24), 
              0px 2px 4px 0px rgba(0,0,0,0.22);
}

.team-desig {
  /* font-size: 13px; */
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 3px;
  line-height: 3em;
  /* padding-left: 0.25em; */
  color: rgba(0, 0, 0, 0.6);
  /* padding-bottom: 10px; */
}

.team-name {
  /* font-size: 13px; */
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 3em;
  /* padding-left: 0.25em; */
  color: #4c004c;
  /* padding-bottom: 10px; */
}


/* .teamcard:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 7px;
  height: 90px;
  content: "";
  background-color: #4c004c;
} */


.teamcard:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 7px;
  height: 60%;
  content: "";
  background-color: #4c004c;
}

 



.team_image_box::before{
  position: absolute;
  right: 0;
  top: 0;
  width: 40%;
  height: 4px;
  content: "";
  background-color: #4c004c;  
}

.team_image_box::after{
  position: absolute;
  left: 0;
  bottom: 0;
  width: 40%;
  height: 4px;
  content: "";
  background-color: #4c004c;  
}

#clients:hover{
  border-radius: 10px;
}